/* Book */
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: normal;
  src: url('./../../assets/fonts/circular-std/circular-std-book.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: normal;
  src: url('./../../assets/fonts/circular-std/circular-std-book-italic.woff') format('woff');
}

/* Medium */
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 500;
  src: url('./../../assets/fonts/circular-std/circular-std-medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 500;
  src: url('./../../assets/fonts/circular-std/circular-std-medium-italic.woff') format('woff');
}

/* Bold */
@font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 700;
  src: url('./../../assets/fonts/circular-std/circular-std-bold.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 700;
  src: url('./../../assets/fonts/circular-std/circular-std-bold-italic.woff') format('woff');
}

/* Black */
/* @font-face {
  font-family: 'Circular Std';
  font-style: normal;
  font-weight: 900;
  src: url('./../../assets/fonts/circular-std/circular-std-black.woff') format('woff');
}

@font-face {
  font-family: 'Circular Std';
  font-style: italic;
  font-weight: 900;
  src: url('./../../assets/fonts/circular-std/circular-std-black-italic.woff') format('woff');
} */