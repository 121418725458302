:root {
  --tru-fs-xs: 12px;
  --tru-fs-sm: 14px;
  --tru-fs-base: 16px;
  --tru-fs-lg: 18px;
  --tru-fs-xl: 20px;
  --tru-fs-2xl: 24px;
  --tru-fs-3xl: 30px;
  --tru-fs-4xl: 36px;
  --tru-fs-5xl: 48px;
  --tru-fs-6xl: 60px;

  --tru-lh-xs: 16px;
  --tru-lh-sm: 20px;
  --tru-lh-base: 24px;
  --tru-lh-lg: 28px;
  --tru-lh-xl: 28px;
  --tru-lh-2xl: 32px;
  --tru-lh-3xl: 36px;
  --tru-lh-4xl: 40px;
  --tru-lh-5xl: 48px;
  --tru-lh-6xl: 60px;

  --tru-fw-normal: 400;
  --tru-fw-medium: 500;
  --tru-fw-bold: 700;
}

/* xs */
.text-xs-book {
  font-size: var(--tru-fs-xs);
  line-height: var(--tru-lh-xs);
  font-weight: var(--tru-fw-normal);
}

.text-xs-medium {
  font-size: var(--tru-fs-xs);
  line-height: var(--tru-lh-xs);
  font-weight: var(--tru-fw-medium);
}

.text-xs-bold {
  font-size: var(--tru-fs-xs);
  line-height: var(--tru-lh-xs);
  font-weight: var(--tru-fw-bold);
}

/* sm */
.text-sm-book {
  font-size: var(--tru-fs-sm);
  line-height: var(--tru-lh-sm);
  font-weight: var(--tru-fw-normal);
}

.text-sm-medium {
  font-size: var(--tru-fs-sm);
  line-height: var(--tru-lh-sm);
  font-weight: var(--tru-fw-medium);
}

.text-sm-bold {
  font-size: var(--tru-fs-sm);
  line-height: var(--tru-lh-sm);
  font-weight: var(--tru-fw-bold);
}

/* base */
.text-base-book {
  font-size: var(--tru-fs-base);
  line-height: var(--tru-lh-base);
  font-weight: var(--tru-fw-normal);
}

.text-base-medium {
  font-size: var(--tru-fs-base);
  line-height: var(--tru-lh-base);
  font-weight: var(--tru-fw-medium);
}

.text-base-bold {
  font-size: var(--tru-fs-base);
  line-height: var(--tru-lh-base);
  font-weight: var(--tru-fw-bold);
}

/* lg */
.text-lg-book {
  font-size: var(--tru-fs-lg);
  line-height: var(--tru-lh-lg);
  font-weight: var(--tru-fw-normal);
}

.text-lg-medium {
  font-size: var(--tru-fs-lg);
  line-height: var(--tru-lh-lg);
  font-weight: var(--tru-fw-medium);
}

.text-lg-bold {
  font-size: var(--tru-fs-lg);
  line-height: var(--tru-lh-lg);
  font-weight: var(--tru-fw-bold);
}

/* xl */
.text-xl-book {
  font-size: var(--tru-fs-xl);
  line-height: var(--tru-lh-xl);
  font-weight: var(--tru-fw-normal);
}

.text-xl-medium {
  font-size: var(--tru-fs-xl);
  line-height: var(--tru-lh-xl);
  font-weight: var(--tru-fw-medium);
}

.text-xl-bold {
  font-size: var(--tru-fs-xl);
  line-height: var(--tru-lh-xl);
  font-weight: var(--tru-fw-bold);
}

/* 2xl */
.text-2xl-book {
  font-size: var(--tru-fs-2xl);
  line-height: var(--tru-lh-2xl);
  font-weight: var(--tru-fw-normal);
}

.text-2xl-medium {
  font-size: var(--tru-fs-2xl);
  line-height: var(--tru-lh-2xl);
  font-weight: var(--tru-fw-medium);
}

.text-2xl-bold {
  font-size: var(--tru-fs-2xl);
  line-height: var(--tru-lh-2xl);
  font-weight: var(--tru-fw-bold);
}

/* 3xl */
.text-3xl-book {
  font-size: var(--tru-fs-3xl);
  line-height: var(--tru-lh-3xl);
  font-weight: var(--tru-fw-normal);
}

.text-3xl-medium {
  font-size: var(--tru-fs-3xl);
  line-height: var(--tru-lh-3xl);
  font-weight: var(--tru-fw-medium);
}

.text-3xl-bold {
  font-size: var(--tru-fs-3xl);
  line-height: var(--tru-lh-3xl);
  font-weight: var(--tru-fw-bold);
}

/* 4xl */
.text-4xl-book {
  font-size: var(--tru-fs-4xl);
  line-height: var(--tru-lh-4xl);
  font-weight: var(--tru-fw-normal);
}

.text-4xl-medium {
  font-size: var(--tru-fs-4xl);
  line-height: var(--tru-lh-4xl);
  font-weight: var(--tru-fw-medium);
}

.text-4xl-bold {
  font-size: var(--tru-fs-4xl);
  line-height: var(--tru-lh-4xl);
  font-weight: var(--tru-fw-bold);
}

/* 5xl */
.text-5xl-book {
  font-size: var(--tru-fs-5xl);
  line-height: var(--tru-lh-5xl);
  font-weight: var(--tru-fw-normal);
}

.text-5xl-medium {
  font-size: var(--tru-fs-5xl);
  line-height: var(--tru-lh-5xl);
  font-weight: var(--tru-fw-medium);
}

.text-5xl-bold {
  font-size: var(--tru-fs-5xl);
  line-height: var(--tru-lh-5xl);
  font-weight: var(--tru-fw-bold);
}

/* 6xl */
.text-6xl-book {
  font-size: var(--tru-fs-6xl);
  line-height: var(--tru-lh-6xl);
  font-weight: var(--tru-fw-normal);
}

.text-6xl-medium {
  font-size: var(--tru-fs-6xl);
  line-height: var(--tru-lh-6xl);
  font-weight: var(--tru-fw-medium);
}

.text-6xl-bold {
  font-size: var(--tru-fs-6xl);
  line-height: var(--tru-lh-6xl);
  font-weight: var(--tru-fw-bold);
}