:root {
  --tru-accent50: #ECF6FA;
  --tru-accent100: #D9EEF6;
  --tru-accent200: #B4DCED;
  --tru-accent300: #8ECBE4;
  --tru-accent400: #68BADB;
  --tru-accent500: #42A8D1;
  --tru-accent600: #2E94BD;
  --tru-accent700: #248AB3;
  --tru-accent800: #1A80A9;
  --tru-accent900: #066C95;

  --tru-gray50: #F9FAFB;
  --tru-gray100: #F3F4F6;
  --tru-gray200: #E5E7EB;
  --tru-gray300: #D1D5DB;
  --tru-gray400: #9CA3AF;
  --tru-gray500: #6B7280;
  --tru-gray600: #4B5563;
  --tru-gray700: #374151;
  --tru-gray800: #1F2937;
  --tru-gray900: #111827;

  --tru-red50: #FEF2F2;
  --tru-red100: #FEE2E2;
  --tru-red200: #FECACA;
  --tru-red300: #FCA5A5;
  --tru-red400: #F87171;
  --tru-red500: #EF4444;
  --tru-red600: #DC2626;
  --tru-red700: #B91C1C;
  --tru-red800: #991B1B;
  --tru-red900: #7F1D1D;

  --tru-yellow50: #FFFBEB;
  --tru-yellow100: #FEF3C7;
  --tru-yellow200: #FDE68A;
  --tru-yellow300: #FCD34D;
  --tru-yellow400: #FBBF24;
  --tru-yellow500: #F59E0B;
  --tru-yellow600: #D97706;
  --tru-yellow700: #B45309;
  --tru-yellow800: #92400E;
  --tru-yellow900: #78350F;

  --tru-green50: #ECFDF5;
  --tru-green100: #D1FAE5;
  --tru-green200: #A7F3D0;
  --tru-green300: #6EE7B7;
  --tru-green400: #34D399;
  --tru-green500: #10B981;
  --tru-green600: #059669;
  --tru-green700: #047857;
  --tru-green800: #065F46;
  --tru-green900: #064E3B;

  --tru-blue50: #EFF6FF;
  --tru-blue100: #DBEAFE;
  --tru-blue200: #BFDBFE;
  --tru-blue300: #93C5FD;
  --tru-blue400: #60A5FA;
  --tru-blue500: #3B82F6;
  --tru-blue600: #2563EB;
  --tru-blue700: #1D4ED8;
  --tru-blue800: #1E40AF;
  --tru-blue900: #1E3A8A;

  --tru-indigo50: #EEF2FF;
  --tru-indigo100: #E0E7FF;
  --tru-indigo200: #C7D2FE;
  --tru-indigo300: #A5B4FC;
  --tru-indigo400: #818CF8;
  --tru-indigo500: #6366F1;
  --tru-indigo600: #4F46E5;
  --tru-indigo700: #4338CA;
  --tru-indigo800: #3730A3;
  --tru-indigo900: #312E81;

  --tru-purple50: #F5F3FF;
  --tru-purple100: #EDE9FE;
  --tru-purple200: #DDD6FE;
  --tru-purple300: #C4B5FD;
  --tru-purple400: #A78BFA;
  --tru-purple500: #8B5CF6;
  --tru-purple600: #7C3AED;
  --tru-purple700: #6D28D9;
  --tru-purple800: #5B21B6;
  --tru-purple900: #4C1D95;

  --tru-pink50: #FDF2F8;
  --tru-pink100: #FCE7F3;
  --tru-pink200: #FBCFE8;
  --tru-pink300: #F9A8D4;
  --tru-pink400: #F472B6;
  --tru-pink500: #EC4899;
  --tru-pink600: #DB2777;
  --tru-pink700: #BE185D;
  --tru-pink800: #9D174D;
  --tru-pink900: #831843;
  --tru-white900: #FFFFFF;
  --tru-black: #000000;
}