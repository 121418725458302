.public-DraftEditor-content {
  min-height: 160px;
}

.public-DraftEditor-content > div > h1 {
  font-size: var(--tru-fs-4xl);
  line-height: var(--tru-lh-4xl);
}

.public-DraftEditor-content > div > h2 {
  font-size: var(--tru-fs-3xl);
  line-height: var(--tru-lh-3xl);
}

.public-DraftEditor-content > div > h3 {
  font-size: var(--tru-fs-2xl);
  line-height: var(--tru-lh-2xl);
}

.public-DraftEditor-content > div > span {
  font-size: var(--tru-fs-base);
  line-height: var(--tru-lh-base);
}

.public-DraftStyleDefault-block > span > a {
  text-underline-offset: 4px;
  color: var(--tru-accent500);
  transition: color 0.2s cubic-bezier(0, 0, 1, 1);
}

.public-DraftStyleDefault-block > span > a:hover {
  text-decoration: underline;
  color: var(--tru-accent600);
}

.public-DraftStyleDefault-block > span > a:active {
  text-decoration: none;
}

.rdw-link-modal-btn {
  text-align: center;
}

.rdw-link-modal {
  height: auto;
}

.public-DraftStyleDefault-block,
.public-DraftEditorPlaceholder-root {
  font-size: var(--tru-fs-base);
  line-height: var(--tru-lh-base);
  margin: 0;
}

.rdw-editor-toolbar {
  gap: 4px;
}

.rdw-inline-wrapper,
.rdw-link-wrapper,
.rdw-list-wrapper {
  gap: 4px;
  margin-bottom: 0;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0;
}

.rdw-option-wrapper {
  height: auto;
  /* margin: 0 4px; */
  margin: 0;
  padding: 4px 16px;
  border: 0.5px solid var(--tru-gray200);
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0, 0, 1, 1);
  transition-property: border-color, background-color;
}

.rdw-option-wrapper,
.rdw-option-wrapper:hover,
.rdw-option-wrapper:active {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.rdw-option-wrapper:hover {
  border-color: var(--tru-gray300);
}

.rdw-option-wrapper img {
  width: 20px;
  transition: filter 0.2s cubic-bezier(0, 0, 1, 1);
}

.rdw-option-wrapper.rdw-option-active {
  background-color: var(--tru-gray700);
}

.rdw-option-wrapper.rdw-option-active img {
  filter: invert(100%) brightness(200%);
}
