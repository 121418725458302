.rbc-month-view {
  border: 0;
}

.rbc-header {
  padding: 0;
  border-color: var(--tru-gray200);
}

.rbc-header + .rbc-header {
  border-color: var(--tru-gray200);
}

.rbc-month-row + .rbc-month-row {
  border-color: var(--tru-gray200);
}

.rbc-off-range-bg {
  background-color: initial;
}

.rbc-date-cell {
  padding: 0;
}

.rbc-date-cell.rbc-now {
  font-weight: normal;
}

.rbc-row-content {
  z-index: auto;
}

.rbc-row-segment {
  padding: 4px;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-view .rbc-header {
  border-bottom: 0;
}

.rbc-time-content {
  margin-top: -1px;
  border-top: 0;
}

.rbc-time-content::-webkit-scrollbar {
  display: none;
}

.rbc-day-slot .rbc-time-slot {
  border-color: var(--tru-gray200);
}

.rbc-time-content > * + * > * {
  border-color: var(--tru-gray200);
}

.rbc-timeslot-group {
  border-bottom: 0;
}

.rbc-timeslot-group {
  min-height: 128px;
}

.rbc-time-header.rbc-overflowing {
  position: relative;
  z-index: 1;
  margin-right: 0 !important;
  border-color: var(--tru-gray200);
  border-right: 0;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  background-color: var(--tru-white900);
}

.rbc-time-view .rbc-header + .rbc-header,
.rbc-time-header-content {
  border-left: 0;
}

.rbc-time-view {
  border: 0;
}

.rbc-today {
  background-color: initial;
}

.rbc-day-slot .rbc-event {
  min-height: 34px;
  padding: 4px !important;
  border: 0;
  border-radius: 0;
  background-color: initial;
}

.rbc-event-label {
  display: none;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 0;
}

.rbc-current-time-indicator {
  position: absolute;
  height: 2px;
  background-color: var(--tru-red500);
}

.rbc-current-time-indicator::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 9px;
  height: 9px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: var(--tru-red500);
}

.rbc-label {
  display: block;
  min-width: 56px;
  padding: 0 8px;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 1.33;
  color: var(--tru-gray400);
}

.rbc-timeslot-group:first-child .rbc-label {
  display: none;
}

.rbc-show-more {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
}